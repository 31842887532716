import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Terraria",
  "time": "stuff",
  "index": 1,
  "type": "project",
  "hidden": false,
  "splash": "Terraria turned MS Windows meme"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/Terraria"
      }}>{`Terraria`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2017`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`LibGDX`}</inlineCode>{` `}<inlineCode parentName="p">{`Java`}</inlineCode>{` `}<inlineCode parentName="p">{`Box2D`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/Terraria" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`This Terraria emulation was a summative project I developed in highschool which inadvertently became a passive aggressive attack on Windows.`}</p>
    <p><strong parentName="p">{`Cool Features:`}</strong></p>
    <ol>
      <li parentName="ol">{`Random terrain distribution via a pseudorandom noise algorithm derived from `}<em parentName="li">{`Perlin Noise`}</em></li>
      <li parentName="ol">{`Random resource distribution via the same noise function`}</li>
      <li parentName="ol">{`Supports basic game mechanics including movement & physics, mining & tools, and inventory & storage`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      